const initialState = {
    item: {
        id: null
    },
    loading: false,
    params: null,
    lastWasAdd: false
}

const cellarReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'DELETE_CELLAR':
        case 'CELLARS_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'EDIT_CELLAR':
        case 'ADD_CELLAR':
        case 'GET_CELLAR':
            return {
                item: action.item == undefined ? initialState.item : action.item,
                loading: false,
                params: action.params,
                lastWasAdd: action.lastWasAdd
            }
        case 'RESET':

            return {
                ...initialState
            }
        default:
            return {...state}
    }
}
export default cellarReducer
