const initialState = {
    data: [], item: {}, params: {}, total: 1, loading: false, newTasting: false
}

const tastingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_TASTINGS":
            return {
                ...state, loading: action.loading
            }
        case 'CHANGE_WINE':
        case 'ADD_TASTING':
        case 'GET_TASTING':
        case 'EDIT_TASTING':
        case 'GET_TASTINGS':

            return {
                ...state,
                item: action.item,
                data: action.data,
                params: action.params,
                total: action.totalPages,
                step: action.step,
                loading: action.loading,
                newTasting: action.newTasting
            }
        case "RESET":
            return {
                ...state, ...initialState
            }
        case 'DELETE_TASTING':
        default:
            return {...state}
    }
}
export default tastingsReducer
