const initialState = {
    data: [],
    total: 1,
    params: {}
}

const termReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TERMS':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'ADD_TERM':
            return {...state};
        case 'EDIT_TERM':
            return {...state};
        case 'DELETE_TERM':
            return {...state}
        default:
            return {...state}
    }
}
export default termReducer
