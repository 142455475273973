const initialState = {
    data: [], total: 1, params: {}, allData: [], form: {
        open: false, mode: "add"
    }, loading: false
}

const vitigniReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOADING_VITIGNI':
            return {
                ...state, loading: action.loading
            }
        case 'GET_VITIGNI':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                loading: action.loading
            }
        case 'CU_FORM':
            return {
                ...state, params: action.params, form: action.form
            };
        case 'ADD_VITIGNO':
            return {...state};
        case 'EDIT_VITIGNO':
            return {...state};
        case 'DELETE_VITIGNO':
            return {...state};
        default:
            return {...state};
    }
}
export default vitigniReducer
