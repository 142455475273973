// ** Checks if an object is empty (returns boolean)

import React, {Fragment, useContext} from "react";
import {AbilityContext} from "./context/Can";
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";

export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {month: 'short', day: 'numeric', year: 'numeric'}) => {
    if (!value) return value
    return new Intl.DateTimeFormat('it-IT', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = {month: 'short', day: 'numeric'}

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: 'numeric', minute: 'numeric'}
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/'
    if (userRole === 'taster') return '/'
    return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const isObjectEmpty = (object, fieldsList = []) => {

    if (fieldsList.length > 0) {
        for (var key in object) {
            if (fieldsList.includes(key)) {
                if (object[key] !== null && object[key] != "")
                    return false;
            }
        }
    } else {
        for (var key in object) {
            if (object[key] !== null && object[key] != "")
                return false;
        }
    }

    return true;

}

const actionMap = {
    create: "create",
    read: "read",
    update: "update",
    delete: "delete",
    add: "create",
    view: "read",
    list: "read",
    edit: "update",
    form: "update"
}

export const CanActionLink = ({entity, action, item, label, icon, show = true}) => {

    const ability = useContext(AbilityContext);
    const abilityAction = actionMap[action];


    if (show && item.id && ability.can(abilityAction, entity)) {

        return <Fragment>
            <Link class={entity + "-" + action + "-action"} to={`/${entity}/${action}/${item.id}`}
                  id={`${action}-tooltip-${item.id}`}>
                {icon}
            </Link>
            <UncontrolledTooltip placement='top' target={`${action}-tooltip-${item.id}`}>
                {label}
            </UncontrolledTooltip>
        </Fragment>
    } else {
        return null;
    }


}

export const LockUserFormOverlay = (props) => {

    const ability = useContext(AbilityContext);

    const lockStyle = {
        position: "absolute",
        top: 0,
        bottom: 45,
        left: 0,
        right: 0,
        padding: 0,
        zIndex: 10000,
        display: "flex",
        flexDirection: "row-reverse"
    }

    if (ability.can("update", "tasting") && props.entity?.user?.id == getUserData().id ||
        getUserData().level == "admin"
    ) {
        return props.children;
    }

    return <Fragment>
        <div class="lock-overlay" style={lockStyle}>
        </div>
        {props.children}
    </Fragment>


}
