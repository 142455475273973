const initialState = {
    data: [], total: 1, params: {
        sort: {
            column: 'updated_at', order: 'desc'
        }
    }, allData: [],
    loading: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'LOADING_USERS':
            return {
                ...state,
                loading: action.loading
            }

        case 'GET_USERS':
            return {
                ...state, allData: action.allData, data: action.data, total: action.totalPages, params: action.params,
                loading: action.loading
            }
        case 'DELETE_USER':
            return {...state}
        default:
            return {...state}
    }
}
export default userReducer
