// ** Redux Imports
import {combineReducers} from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import wines from '@src/views/wine/store/reducer'
import cellars from '@src/views/cellar/store/reducer'
import vitigni from '@src/views/vitigni/store/reducer'
import abjectives from '@src/views/abjective/store/reducer'
import terms from '@src/views/terms/store/reducer'
import trees from '@src/views/trees/store/reducer'
import tastings from '@src/views/tasting/store/reducer'
import users from '@src/views/user/store/reducer'
import stats from '@src/views/stats/store/reducer'
import filters from '@src/views/filters/store/reducer'

const rootReducer = combineReducers({
    auth,
    navbar,
    layout,
    stats,
    wines,
    cellars,
    vitigni,
    abjectives,
    terms,
    trees,
    tastings,
    users,
    filters,


})

export default rootReducer
