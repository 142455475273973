const initialState = {
    series: [],
    categories: []
}

const statsReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_STATS':
            return {
                ...state,
                series: action.series,
                categories: action.categories
            }
        default:
            return {...state}
    }
}
export default statsReducer
