const initialState = {
    data: [], total: 1, params: {
        sort: {
            column: 'updated_at', order: 'desc'
        }
    }, loading: false
}

const abjectiveReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOADING_ABJECTIVES':
            return {
                ...state, loading: action.loading
            }
        case 'GET_ABJECTIVES':
            return {
                ...state, data: action.data, total: action.totalPages, params: action.params, loading: action.loading
            }
        case 'ADD_ABJECTIVE':
            return {...state};
        case 'EDIT_ABJECTIVE':
            return {...state};
        case 'DELETE_ABJECTIVE':
            return {...state}
        default:
            return {...state}
    }
}
export default abjectiveReducer
