const initialState = {
    data: {data: []}, item: {
        id: null
    }, loading: false, params: null, lastWasAdd: false
}

const winesReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'DELETE_WINE':
        case 'WINES_LOADING':
            return {
                ...state, loading: true
            };
        case 'STOP_WINES_LOADING':
            return {
                ...state, loading: false
            };
        case 'GET_WINES':
            return {
                ...state, data: action.data, loading: false, lastWasAdd: false
            }
        case 'GET_WINE':
        case 'EDIT_WINE':
        case 'ADD_WINE':
            return {
                ...state,
                item: action.item == undefined ? initialState.item : action.item,
                loading: false,
                params: action.params,
                lastWasAdd: action.lastWasAdd
            }
        case 'RESET':
            return {
                ...initialState
            }
        default:
            return {...state}
    }
}
export default winesReducer
