const initialState = {
    data: {
        terms: [],
        properties: [],
        tree: [],
        count: 0
    },
    treeType: 'aroma',
    params: {},
    loading: true
}

const treeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TREE':
            return {
                ...state,
                data: action.data,
                treeType: action.treeType,
                params: action.params
            }
        case 'GET_TERMS':
            return {
                ...state,
                data: action.data,
                treeType: action.treeType,
                params: action.params
            }
        case 'ADD_TERM':
            return {...state};
        case 'EDIT_TERM':
            return {...state};
        case 'DELETE_TERM':
            return {...state}
        case 'LOADING_TERM':
        case 'LOADING_TREE':
            return {
                ...state,
                loading: action.loading
            }
        default:
            return {...state}
    }
}
export default treeReducer
