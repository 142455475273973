import {Ability} from '@casl/ability'
import {userAbilities} from './userAbilities'
import {getUserData} from "../../utility/Utils";

//  Read ability from localStorage
// * Handles auto fetching previous userAbilities if already logged in user
// ? You can update this if you store user userAbilities to more secure place
// ! Anyone can update localStorage so be careful and please update this


export function GetUserAbility(){
    const userData = getUserData();
    const userLevel = userData?.level || 'guest';
    return userAbilities[userLevel];
}

export default new Ability(GetUserAbility());


