// ** Initial user ability
export const userAbilities = {
    admin: [{
        action: 'manage',
        subject: 'all'
    }],
    taster: [
        {action: "read", subject: "dashboards"},
        {action: "read", subject: "stats"},
        {action: "read", subject: "maps"},
        {action: "read", subject: "manual"},
        {action: ["create", "read", "update"], subject: "wine"},
        {action: ["create", "read", "update"], subject: "cellar"},
        {action: ["create","read","update","delete"], subject: "tasting"},
        {action: "read", subject: "faq"},
        {action: "read", subject: "page"}
    ],
    guest: [
        {action: "read", subject: "dashboard"},
        {action: "read", subject: "stats"},
        {action: "read", subject: "maps"},
        {action: "read", subject: "manual"},
        {action: "read", subject: "wine"},
        {action: "read", subject: "cellar"},
        {action: "read", subject: "faq"},
        {action: "read", subject: "page"}

    ]
}

export const _ = undefined
