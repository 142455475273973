export const emptyAnnata = {
    label: null,
    value: null
};

export const maxAnnata = {
    label: "Ultima Annata",
    value: "ultima-annata"
};

export const filterDefault = {loading: true, data: [], selected: []}

const initialState = {
    entity: "vino",
    entityModified: false,
    data: {
        data: [],
        current_page: 1,
        next_page_url: "",
        prev_page_url: "",
        per_page: null,
        total: null
    },
    params: {
        per_page: 25,
        page: 1,
        filters: {
            cellars: filterDefault,
            wines: filterDefault,
            denominations: filterDefault,
            vitigni: filterDefault,
            annate: {
                loading: true, data: {from: [], to: []},
                selected: {
                    from: emptyAnnata,
                    to: emptyAnnata
                }
            }
        },
        sort: {
            column: 'created_at',
            order: 'desc'
        }
    },
    loading: false
}

const filtersReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'ENTITY_MODIFIED':
            return {
                ...state,
                entityModified: true
            }
        case 'FILTERS_LOADING':
            return {
                ...state,
                loading: true,
                entityModified: false
            };
        case 'FILTERS_GET':

            return {
                ...state,
                entity: action.entity,
                data: action.data == undefined ? state.data : action.data,
                loading: false,
                params: action.params,
                entityModified: false
            }
        case "RESET":
            return {
                ...state,
                ...initialState,
                entity: action.entity,
                entityModified: false
            }
        default:
            return {...state}
    }
}
export default filtersReducer;
