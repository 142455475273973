import mock from '../mock'

const data = {
    faqData: {
        vini: {
            title: "Vini", qandA: [{
                question: 'Posso inserire il vino se non lo trovo?',
                ans: 'Certamente, prima però cercalo attentamente magari ricercando la cantina e valutando tra i vini già inseriti. Se non c\'è allora inseriscilo pure dal menu in alto a destra.',
                link: "https://divagazionieteree.wordpress.com/2022/03/03/tastingnotes-aggiungere-un-vino/"
            }]
        },
        registrazione: {
            title: 'Registrazione', qandA: [{
                question: 'Devo registrarmi per poter usare l\'app?',
                ans: "Si, per usare l'app serve un account, gratuito, che è possibile fare la prima volta che si utilizza l'app dalla schermata iniziale.",
                link: "https://divagazionieteree.wordpress.com/2022/02/18/tastingnotes-registrazione-utente/"
            }]
        },  degustazione: {
            title: 'Degustazioni', qandA: [{
                question: 'Come faccio ad inserire una nuova degustazione?',
                ans: 'Per inserire una nuova degustazione bisogna cercare nella schermata "Home" il nome del vino, cliccare sulla freccia a destra e scegliere "Degusta il Vino.\n' + '\n' + 'Una volta scelta l\'annata si entra nel menu degustazioni e si possono inserire le informazioni.',
                link: "https://divagazionieteree.wordpress.com/2022/02/26/tastingnotes-inserire-una-nuova-degustazione/"
            }, {
                question: 'Se inserisco una degustazione avvenuta tempo fa, posso cambiare la data?',
                ans: 'Certamente, di default la degustazione viene registrata con la data di inserimento ma è possibile modificarla direttamente nella schermata iniziale cliccare sulla data stessa.',
                link: "https://divagazionieteree.wordpress.com/2022/02/28/tastingnotes-visualizzare-una-degustazione-esistente/"
            }, {
                question: 'Se partecipo ad una degustazione alla cieca come faccio ad inserire il vino?',
                ans: 'Nella schermata "Home" cercare il vino "Vino Segreto 1" ed inserire i dati della degustazione utilizzando quel profilo. Quando il vino viene svelato ritornare nella degustazione e con la funzione "Cambio Vino" sostituire la dicitura Vino segreto con il nome reale.',
                link: "https://divagazionieteree.wordpress.com/2022/03/01/tastingnotes-degustazione-alla-cieca/"
            },]
        }
    }
}

mock.onGet('/faq/data').reply(config => {
    const {q = ''} = config.params
    const queryLowered = q.toLowerCase()

    const filteredData = {}

    Object.entries(data.faqData).forEach(entry => {
        const [categoryName, categoryObj] = entry
        const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
            return qAndAObj.question.toLowerCase().includes(queryLowered)
        })
        if (filteredQAndAOfCategory.length) {
            filteredData[categoryName] = {...categoryObj, qandA: filteredQAndAOfCategory}
        }
    })

    return [200, filteredData]
})



